<template>
  <v-dialog
    v-model="leavingBruqiWarning"
    max-width="520"
  >
    <v-card v-if="targetLink" rounded="xl">
      <v-card-title>
        You are leaving Bruqi
        <v-btn
          class="dialog-close"
          elevation="0"
          icon
          @click="leavingBruqiWarning = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p class="mb-4">
          Bruqi is <span class="text-decoration-underline">not</span> affiliated with the site you’re being redirected to.
        </p>
        <p class="mb-4">
          We cannot guarantee your safety on these sites, and they <span class="text-decoration-underline">may contain malware or other harmful content.</span>
        </p>
        <!-- <p class="mb-4 text-decoration-underline">
          We strongly advise against visiting these sites.
        </p> -->
        <v-alert
          outlined
          color="error"
          variant="tonal"
          rounded="xl"
        >
          <span class="text-black">
            We strongly advise against visiting these sites.
          </span>
          <!-- <span class="text-black">
            When performing DIY takedowns, never share private information or your email with these sites. It is extremely likely it will be sold and spammed.
          </span> -->
        </v-alert>
      </v-card-text>

      <v-card-actions class="flex-wrap flex-column-reverse flex-sm-row align-stretch align-sm-center px-4">
        <div class="flex-grow-1">
          <v-checkbox
            v-model="hideLinkPrompt"
            label="Do not show this again"
            hide-details
            class="mb-0 pb-0"
          />
          <v-spacer />
        </div>
        <div class="d-flex">
          <v-spacer />
          <v-btn
            color="primary"
            variant="tonal"
            class="px-4"
            size="large"
            rounded="lg"
            @click="openLink"
          >
            <v-icon start>
              mdi-open-in-new
            </v-icon>
            Continue
          </v-btn>
          <v-btn
            variant="tonal"
            class="px-4"
            size="large"
            rounded="lg"
            @click="leavingBruqiWarning = false"
          >
            Cancel
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useLocalStorage } from '@vueuse/core'

// add event listeners to all links with data-warn attribute, to stop link opening and set the target link as the 
const targetLink = ref(null)
const leavingBruqiWarning = computed({
  get: () => !!targetLink.value,
  set: (value) => {
    if (!value) {
      targetLink.value = null
    }
  }
})

const hideLinkPrompt = useLocalStorage('hide-link-prompt', false)
const listener = (event) => {
  if (event.target instanceof HTMLAnchorElement && event.target.dataset.warn) {
    event.preventDefault()
    targetLink.value = event.target.href
  }
}
if (!hideLinkPrompt.value) {
  document.addEventListener('click', listener)
}

function openLink() {
  window.open(targetLink.value, '_blank')
  leavingBruqiWarning.value = false
  if (hideLinkPrompt.value) {
    document.removeEventListener('click', listener)
  }
}

</script>

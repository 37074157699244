export const mode = import.meta.env.VITE_MODE
export const publishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY
export const memberstackAppId = import.meta.env.VITE_MEMBERSTACK_APP_ID
export const plans = [
  {
    plan: 'small',
    title: 'Starter',
    tag: 'Super Affordable',
    for: 'For newbie creators just starting out and retired creators.',
    removals: 'Weekly',
    description: 'Essential protection designed for  people new to exclusive content. The industry-leading scans are the same, just weekly.',
    monthly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_SMALL_MONTHLY,
      price: 29
    },
    yearly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_SMALL_YEARLY,
      price: 24
    },
    freeTrial: true
  },
  {
    plan: 'medium',
    title: 'Creator Pro',
    tag: 'Most Popular',
    for: 'Best for the top 25% to 1% on OF or equivalent elsewhere.',
    removals: 'Daily',
    description: 'For serious creators looking to get protected. The Bruqi Scans happen daily removing tons of leaks.',
    monthly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_MEDIUM_MONTHLY,
      price: 99
    },
    yearly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_MEDIUM_YEARLY,
      price: 79
    },
    freeTrial: true
  },
  {
    plan: 'large',
    title: 'Top 1%',
    tag: 'Best Protection',
    for: 'Only for the top 1% on OF or equivalent elsewhere.',
    removals: 'Hourly',
    description: 'For large creators looking to get the best protection possible. Reports happen hourly, so leaks are removed before they spread.',
    monthly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_LARGE_MONTHLY,
      price: 249
    },
    yearly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_LARGE_YEARLY,
      price: 199
    }
  }
]
export const snippets = [
  'All content is under copyright. Enforced by bruqi.com.',
  'All content is under copyright. Reproduction or distribution without permission is illegal. Enforced by bruqi.com.',
]
export const accountStatuses = {
  'pending-review': 'Requires Review',
  'pending-verification': 'Pending Verification',
  'active': 'Active',
  'onhold': 'Paused',
  'inactive': 'Deactivated',
  'unsubscribed': 'Unsubscribed'
}
export const getHeaderText = (usernames, links) => {
  return `These images and videos are copyrighted materials belonging to content creator performing under ${usernames.join(', ')} stagenames. The content creator distributes their content on official platforms only and strictly forbids any form of distribution outside of these platforms - ${links.join(', ')}.\n\nJames Robinson\n`
}

export const PLATFORMS = [
  {
    title: 'OnlyFans',
    domain: 'onlyfans.com',
    subscription: true
  },
  {
    title: 'Fansly',
    domain: 'fansly.com',
    subscription: true
  },
  {
    title: 'Fanvue',
    domain: 'fanvue.com',
    subscription: true
  },
  {
    title: 'Chaturbate',
    domain: 'chaturbate.com',
    subscription: true
  },
  {
    title: 'Cam4',
    domain: 'cam4.com',
    subscription: true
  },
  {
    title: 'Patreon',
    domain: 'patreon.com',
    subscription: true
  },
  {
    title: 'ManyVids',
    domain: 'manyvids.com',
    subscription: true
  },
  {
    title: 'JustForFans',
    domain: 'justfor.fans',
    subscription: true
  },
  {
    title: 'Bressels',
    domain: 'brezzels.com',
    subscription: true
  },
  {
    title: 'Mym.fans',
    domain: 'mym.fans',
    subscription: true
  },
  {
    title: '4Based',
    domain: '4based.com',
    subscription: true
  },
  {
    title: 'LoyalFans',
    domain: 'loyalfans.com',
    subscription: true
  },
  {
    title: 'Slushy',
    domain: 'slushy.com',
    subscription: true
  },
  {
    title: 'instagram',
    domain: 'instagram.com'
  },
  {
    title: 'tiktok',
    domain: 'tiktok.com'
  },
  {
    title: 'x.com',
    domain: 'x.com'
  },
  {
    title: 'Reddit (u/username)',
    domain: 'reddit.com',
    baseUrl: 'reddit.com/u/'
  },
  {
    title: 'Reddit (r/subreddit)',
    domain: 'reddit.com',
    baseUrl: 'reddit.com/r/',
    placeholder: 'your-subreddit'
  },
  {
    title: 'snapchat',
    domain: 'snapchat.com'
  }
].map((platform)  => {
  platform.baseUrl = platform.baseUrl || platform.domain
  !platform.baseUrl.endsWith('/') && (platform.baseUrl += '/')
  platform.placeholder = platform.placeholder || 'your-username'
  return platform
})

export const USERNAME_TYPES = [
  { id: 'display_name', title: 'Display Name' },
  { id: 'nickname', title: 'Nickname' },
  { id: 'real_name', title: 'Real Name' },
  { id: 'stage_name', title: 'Stage Name' },
  { id: 'old_account', title: 'Old Account' },
  { id: 'legacy', title: 'Legacy', active: false }
]
export const searchScanTypes = ['welcome', 'weekly', 'daily', 'hourly']

import { defineStore } from 'pinia'
import posthog from 'posthog-js'
import memberstack from '@/services/memberstack'
import Parse from '@/services/parse'
import { mode } from '@/config'
let pollTimeout
export const useAppStore = defineStore('app', {
  state: () => ({
    member: null,
    token: window.localStorage.getItem('_ms-mid'),
    billingLoading: false,
    account: null,
    supermembers: mode !== 'production' ? [] : undefined,
    supermemberId: null
  }),
  actions: {
    async initialize() {
      posthog.opt_in_capturing()
      if (!this.token) { return }
      if (!this.member) {
        this.member = await memberstack.getCurrentMember().then(({ data }) => data)
        if (!this.member) {
          posthog.reset()
          return
        }
      }
      mode !== 'production' && await this.initializeSupermembers()
      await this.pollMemberJson()
      posthog.identify(this.member.id, {
        email: this.member.auth.email,
        name: this.member.customFields.name
      })
    },
    setMember({ member, tokens }) {
      this.member = member
      this.token = tokens.accessToken
      return this.pollMemberJson()
    },
    setMemberJson(memberJson) {
      this.account = memberJson
    },
    getPollWaitTime() {
      if (!this.account || !this.account.isSubscribed) {
        return 1000 + Math.random() * 1000
      }
      return 3000 + Math.random() * 5000
    },
    async pollMemberJson() {
      clearTimeout(pollTimeout)
      if (!this.member) { return }
      if (mode !== 'production' && this.supermemberId) {
        await Parse.Cloud.run('supermember-json', { token: this.token })
          .then(this.setMemberJson)
          .catch(console.error)
        pollTimeout = setTimeout(this.pollMemberJson, this.getPollWaitTime())
        return this.account
      }
      await memberstack.getMemberJSON()
        .then(({ data }) => data)
        .then(this.setMemberJson)
        .catch(console.error)
      mode !== 'production' && console.log('pollMemberJson', this.account)
      pollTimeout = setTimeout(this.pollMemberJson, this.getPollWaitTime())
      return this.account
    },
    async login({ email, password }) {
      await memberstack.loginMemberEmailPassword({
        email,
        password
      }).then(({ data }) => data).then(this.setMember)
    },
    async loginWithProvider(provider) {
      await memberstack.loginWithProvider({
        provider
      }).then(({ data }) => data).then(this.setMember)
    },
    async signupWithEmail({ name, email, password }, gclid) {
      await memberstack.signupMemberEmailPassword({
        email,
        password,
        customFields: { name, mode, gclid }
      }).then(({ data }) => data).then(this.setMember)
      mode !== 'production' && console.log('signed up with mode', mode)
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'sign_up_complete',
        email,
        eventLabel: 'email'   //variable depends on which button is pressed
      })
      // attempt to send verification email if necessary
      !this.member.verified && await memberstack.sendMemberVerificationEmail()
    },
    async signupWithProvider(provider, gclid) {
      await memberstack.signupWithProvider({
        provider,
        customFields: { mode, gclid }
      }).then(({ data }) => data).then(this.setMember)
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'sign_up_complete',
        provider,
        eventLabel: 'provider'   //variable depends on which button is pressed
      })
      mode !== 'production' && console.log('signed up with mode', mode)
    },
    async sendMemberVerificationEmail() {
      const { data: { success } } = await memberstack.sendMemberVerificationEmail()
      if (!success) { throw new Error('Failed to send verification email') }
    },
    async updateNiches(niches) {
      clearTimeout(pollTimeout)
       await Parse.Cloud.run('member-account-update-niches', {
        niches,
        token: this.token
      }).then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    async addLink(link) {
      clearTimeout(pollTimeout)
      await Parse.Cloud.run('member-account-add-link', {
        link,
        token: this.token
      }).then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    async removeLink(linkId) {
      if (!confirm('Are you sure you want to remove this link?')) { return }
      clearTimeout(pollTimeout)
      await Parse.Cloud.run('member-account-remove-link', {
        linkId,
        token: this.token
      }).then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    async addUsername(username) {
      clearTimeout(pollTimeout)
      await Parse.Cloud.run('member-account-add-username', {
        username,
        token: this.token
      }).then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    async removeUsername(usernameId, dismissingRejected) {
      if (!dismissingRejected && !confirm('Are you sure you want to remove this username?')) { return }
      clearTimeout(pollTimeout)
      await Parse.Cloud.run('member-account-remove-username', {
        usernameId,
        token: this.token
      }).then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    async confirmNoReddit() {
      clearTimeout(pollTimeout)
      if (confirm('Are you sure you want to confirm that you do not have a Reddit account?')) {
        await Parse.Cloud.run('member-account-confirm-no-reddit', {
          token: this.token
        }).then(this.setMemberJson)
      }
      this.pollMemberJson()
      return true
    },
    async confirmNoTwitter() {
      clearTimeout(pollTimeout)
      if (confirm('Are you sure you want to confirm that you do not have a Twitter account?')) {
        await Parse.Cloud.run('member-account-confirm-no-twitter', {
          token: this.token
        }).then(this.setMemberJson)
      }
      this.pollMemberJson()
      return true
    },
    async checkVerification() {
      clearTimeout(pollTimeout)
      await Parse.Cloud.run('member-verification-check', {
        token: this.token
      }).then(this.setMemberJson)
      this.pollMemberJson()
    },
    async startScan() {
      clearTimeout(pollTimeout)
      await Parse.Cloud.run('member-start-welcome-scan', {
        token: this.token
      }).then(this.setMemberJson)
      this.pollMemberJson()
    },
    getStripeCustomerSession() {
      if (!this.account.stripeCustomerId) { return null }
      return Parse.Cloud.run('stripe-customer-session', {
        customer: this.account.stripeCustomerId
      }).catch(() => {})
    },
    async goToBilling() {
      if (this.billingUrl || !this.account.stripeCustomerId) { return null }
      this.billingLoading = true
      try {
        const billingUrl = await Parse.Cloud.run('stripe-billing-portal-url', {
          customer: this.account.stripeCustomerId
        })
        window.location.href = billingUrl
      } catch (error) {
        this.billingLoading = false
        throw new Error('Failed to get billing portal URL to Stripe. Please contact us.')
      }
    },
    sendResetPasswordEmail({ email }) {
      return memberstack.sendMemberResetPasswordEmail({ email })
        .then(({ data }) => data)
        .catch((error) => {
          return error?.message
        })
    },
    async resetPassword({ token, password }) {
      const { data: { success } } = await memberstack.resetMemberPassword({
        token,
        newPassword: password
      })
      if (!success) { throw new Error('Invalid token') }
    },
    async logout() {
      document.getElementsByTagName('html')[0].classList.add('loading')
      await memberstack.logout()
      window.location.href = '/'
    },
    // SUPERMEMBERS
    async initializeSupermembers() {
      const { supermembers, supermemberId } = await Parse.Cloud.run('supermembers-initialize', {
        token: this.token
      })
      this.supermembers = supermembers
      this.supermemberId = supermemberId
    },
    async changeSuperMember(id) {
      await Parse.Cloud.run('supermember-change-member', { token: this.token, id })
    },
  },
})

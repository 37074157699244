// Utilities
import { defineStore } from 'pinia'
import Parse from '@/services/parse'

export const useAdminStore = defineStore('admin', {
  state: () => ({
    initialized: null,
    user: Parse.User.current(),
    users: [],
    accounts: [],
    agencies: [],
    counts: {}
  }),
  getters: {
    isSuperAdmin: (state) => state.user.get('accType') === 'superadmin',
    isAdmin: (state) => state.user.get('accType')?.includes('admin'),
    agenciesWithAccounts: state => state.agencies.map((agency) => {
      agency.accounts = state.accounts
        .filter(account => account.agency?.objectId === agency.objectId && account.objectId !== 'IzDNYsjbde') // TOAGENCY: temp fix
        .sort((a, b) => b.isSubscribed ? 1 : -1)
      agency.activeCount = agency.accounts
        .filter(account => account.isSubscribed).length
      return agency
    }),
  },
  actions: {
    async initialize() {
      const [users, accounts, agencies] = await Parse.Cloud.run('initialize-admin')
        .then((response) => response.map(r => r.map(o => o.toJSON())))
      this.users = users
      this.accounts = accounts
      this.agencies = agencies
      this.counts = await Parse.Cloud.run('admin-counts')
      this.initialized = new Date()
    },
    async logout() {
      document.getElementsByTagName('html')[0].classList.add('loading')
      await Parse.User.logOut()
      window.location.href = '/'
    }
  },
})

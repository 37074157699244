import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export function scanStatus(scan) {
  const status = scan.status || scan.get?.('status')
  const reviewedAt = scan.reviewedAt || scan.get?.('reviewedAt')
  if (status === 'pending') {
    return 'Pending'
  }
  if (status === 'paused') {
    return 'Paused'
  }
  if (status === 'onhold') {
    return 'Onhold'
  }
  if (status === 'errored') {
    return 'Errored'
  }
  if (['scanning', 'predicting', 'scanned'].includes(status)) {
    return 'Scanning'
  }
  if (status === 'completed') {
    if (reviewedAt) {
      return 'Reviewed'
    }
    return 'Ready'
  }
  return status
}

export function scanStatusIcon(scan) {
  const status = scan.status || scan.get?.('status')
  const reviewedAt = scan.reviewedAt || scan.get?.('reviewedAt')
  if (status === 'pending') {
    return 'mdi-timer-sand'
  }
  if (status === 'paused') {
    return 'mdi-pause-circle-outline'
  }
  if (status === 'onhold') {
    return 'mdi-pause-circle'
  }
  if (status === 'errored') {
    return 'mdi-alert-circle-outline'
  }
  if (status === 'completed') {
    if (reviewedAt) {
      return 'mdi-check-all'
    }
    return 'mdi-check'
  }
  if (['scanning', 'scanned'].includes(status)) {
    return 'mdi-loading'
  }
  return ''
}

export function scanStatusColor(status) {
  return {
    pending: '',
    paused: 'warning',
    onhold: 'warning',
    errored: 'error',
    scanning: 'success lighten-1',
    scanned: 'success lighten-1',
    completed: 'success',
  }[status] || ''
}

export function scanTypeIcon(type) {
  return {
    welcome: 'mdi-heart-search',
    weekly: 'mdi-numeric-7-circle-outline',
    daily: 'mdi-hours-24',
    hourly: 'mdi-clock-fast',
    crawl: 'mdi-text-search-variant',
    raid: 'mdi-location-enter',
    legacy: 'mdi-history',
    training: 'mdi-gesture-double-tap',
  }[type] || ''
}

export function takedownRequestStatus(item) {
  if (!item.scanResult && !item.error) {
    return 'Processing Link'
  }
  if (item.error) {
    switch (item.error) {
    case 'GONE':
    case 'NOT FOUND':
    case 'Dead Link':
    case 'No Metadata':
      return 'Link not available'
    case 'Bad Gateway':
    case 'Bad Request':
    case 'BAD REQUEST':
    case 'ECONNRESET':
    case 'ERR_BAD_RESPONSE':
    case 'HPE_INVALID_HEADER_TOKEN':
    case 'INTERNAL SERVER ERROR':
    case 'TOO MANY REQUESTS':
      return 'Can\t process link, retrying'
    default:
      return item.error
    }
  }
  if (item.scanResult.label === undefined) {
    return 'Processing Request'
  }
  if (item.scanResult.label === 1) {
    const [delisted, removalRequested] = [Boolean(item.scanResult.delistedAt), Boolean(item.scanResult.emailedAt || item.scanResult.submittedAt)]
    if (delisted && removalRequested) {
      return 'Delisted & Removal Requested'
    }
    if (delisted) {
      return 'Delisted'
    }
    if (removalRequested) {
      return 'Removal Requested'
    }
    return 'Requesting Delist & Removal'
  }
  if (item.scanResult.label === 0) {
    return 'Not Infringement'
  }
  return 'Pending Review'
}

export function takedownRequestStatusColor(item) {
  if (!item.scanResult && !item.error) {
    return 'warning'
  }
  if (item.error) {
    return 'error'
  }
  if (item.scanResult.label === undefined) {
    return 'warning'
  }
  if (item.scanResult.label === 1) {
    return 'success'
  }
  if (item.scanResult.label === 0) {
    return 'info'
  }
  return 'warning'
}

export const subscriptionStatus = (subscription) => {
  return {
    active: 'Active',
    trialing: 'Trialing',
    past_due: 'Past Due',
    unpaid: 'Unpaid',
    canceled: 'Canceled',
    incomplete: 'Incomplete',
    incomplete_expired: 'Incomplete Expired'
  }[subscription?.status] || ''
}

// globally registered filters
const number = (value, zero = '-') =>  !isNaN(value)
  ? new Intl.NumberFormat().format(value)
  : zero

export default {
  number,
  date (value) {
    if (!value) return '-'
    return new Date(value?.iso || value).toLocaleDateString()
    // return dayjs(value?.iso || value).format('')
  },
  datetime (value) {
    if (!value) return '-'
    const date = new Date(value?.iso || value)
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
  },
  fromNow (value) {
    if (!value) return '-'
    return dayjs(value?.iso || value).fromNow()
  },
  from (value, compare) {
    if (!value) return '-'
    return dayjs(value?.iso || value).from(compare, true)
  },
  imageUrl (value) {
    const url = value?._url
    return url.replace('http://0.0.0.0:1338/', 'https://bruqi.isokosan.com/')
  },
  pluralize (value, singular, plural, zero = 'No') {
    return `${number(value, zero)} ${value === 1 ? singular : plural}`
  },
  dictToArr(dict) {
    return Object.entries(dict).map(([value, text]) => {
      return { value, text }
    })
  },
  plan: value => ({
    free: 'DIY',
    small: 'Starter',
    medium: 'Creator Pro',
    large: 'Top 1%'
  })[value || 'medium'],
  domainFavicon(domain) {
    return `https://icons.duckduckgo.com/ip3/${domain}.ico`
    // return `https://www.google.com/s2/favicons?domain=${domain}`
  }
}
